<template>
    <master-panel pa0 dark>
        <template v-slot:body>
            <v-row>
                <v-col cols="12" class="subtitle-register">
                    Con CxSmart podrás optimizar tu agenda y potenciar
                    tu actividad quirúrgica. Ingresa tus datos y empieza a
                    vivir una nueva experiencia digital.
                </v-col>
                <v-col cols="12">
                    <v-form v-model="valid">
                        <v-row>
                            <v-col cols="12" md="6">
                                <kimsa-text-field
                                    placeholder="Nombre"
                                    :rules="allRules.name"
                                    @change="name = $event"
                                    :value="name"
                                    outlined dense
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <kimsa-text-field
                                    placeholder="e-mail"
                                    :rules="allRules.email"
                                    @change="email = $event"
                                    :value="email"
                                    outlined dense
                                    inputMode="email"
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <kimsa-text-field
                                    placeholder="RUT"
                                    :rules="allRules.rut"
                                    @change="rut = $options.filters.rut($event)"
                                    :value="rut"
                                    outlined dense
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <kimsa-text-field
                                    placeholder="Teléfono"
                                    :rules="allRules.phone"
                                    @change="phone = $event"
                                    :value="phone"
                                    outlined dense
                                    :prefix="phoneCode"
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <kimsa-password-field
                                    placeholder="Contraseña"
                                    :rules="allRules.password"
                                    @change="password = $event"
                                    :value="password"
                                    outlined dense
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <kimsa-password-field
                                    placeholder="Confirmar contraseña"
                                    :rules="allRules.confirmPassword"
                                    @change="confirmPassword = $event"
                                    :value="confirmPassword"
                                    outlined dense
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>
        </template>
        <template v-slot:buttons>
            <v-btn color="secondary" x-large dark depressed class="normal-btn"
                   :loading="loading" :disabled="!valid" @click="onRegister">
                Siguiente
            </v-btn>
        </template>
    </master-panel>
</template>

<script>

import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import KimsaPasswordField from "@/components/common/inputs/KimsaPasswordField";
import {mapActions, mapState} from 'vuex'
import {rules} from "@/utils/Rules";
import MasterPanel from "@/components/layouts/MasterPanel";

export default {
    name: "RegisterDoctorCard",
    components: {
        MasterPanel,
        KimsaTextField,
        KimsaPasswordField,
    },
    data() {
        return {
            valid: false,
            loading: false,
            name: '',
            email: '',
            rut: '',
            phone: '',
            password: '',
            confirmPassword: '',
        }
    },
    computed: {
        allRules() {
            return {
                name: [rules.required, rules.minLength(this.name, 3)],
                email: [rules.required, rules.email],
                rut: [rules.required, rules.rut],
                phone: [rules.required],
                password: [rules.required, rules.minLength(this.password, 6)],
                confirmPassword: [rules.required, rules.sameValue(this.confirmPassword, this.password, 'Contraseña')]
            }
        },
    },
    methods: {
        ...mapActions({register: 'auth/registerDoctor'}),
        async onRegister() {
            const vm = this
            if (!vm.valid) return vm.toast('Datos incorrectos', 'error')

            vm.loading = true
            let payload = {
                name: vm.name,
                email: vm.email,
                rut: vm.rut.replace(/\./g, '').replace(/-/g, ''),
                phone: vm.phone,
                password: vm.password,
            }

            // console.log('payload', payload)

            let response = await vm.register(payload)
            // console.log('response register', response)

            vm.loading = false
            if (response.error) return vm.toast(response.error, 'error')
            if (response.data.error) return vm.toast('Datos incorrectos', 'error')
            vm.$emit('success')
        },
    },
}
</script>

<style scoped>

.subtitle-register {
    font-size: 13px;
    line-height: 15px;
    color: #FFFFFF;
    text-align: center;
}

</style>