<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="8" offset-md="2" lg="6" offset-lg="3">
                <register-doctor-success-card v-if="finished"/>
                <register-doctor-card @success="onSuccess" v-else/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import RegisterDoctorSuccessCard from "@/components/entrance/RegisterDoctorSuccessCard";
import RegisterDoctorCard from "@/components/entrance/RegisterDoctorCard";

export default {
    name: "DoctorRegister",
    components: {RegisterDoctorCard, RegisterDoctorSuccessCard},
    data() {
        return {
            finished: false,
        }
    },
    methods: {
        onSuccess() {
            const vm = this
            vm.finished = true
        }
    },
}
</script>

<style scoped>

</style>