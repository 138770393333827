import {rutValidator} from "@/utils/Common";

export const rules = {
    required: v => !!v || 'Requerido',
    email: v => {
        const pattern = /^\S+@\S+\.\S+$/
        return pattern.test(v) || 'Email invalido'
    },
    minLength: (v, min) => !!v && v.length >= min || `Debe tener minimo ${min} caracteres`,
    length: (v, length) => !!v && v.length !== length || `Debe tener exactamente ${length} caracteres`,
    number: v => !isNaN(Number(`${v}`.replaceAll('.', ''))) || 'Campo numérico',
    object: v => typeof v === 'object' || 'Debe seleccionar una opción',
    array: v => Array.isArray(v) && v.length > 0 || 'Debe seleccionar al menos una opción',
    videos: v => Array.isArray(v) && v.length > 0 || 'Debe subir al menos un video',
    rut: v => rutValidator(v) || 'Rut invalido',
    sameValue: (v, value, field) => !!v && v === value || `No coincide el campo: ${field}`
}