<template>
    <div>
        <div class="center-all text-center">
            <div>
                <v-img class="mx-auto" width="180" :src="imgLogo"/>
            </div>
            <div class="title-success">
                BIENVENIDO
            </div>
            <div class="subtitle-success">
                Nuestra plataforma permitirá a aquellos pacientes
                que consultan por primera vez para una cirugía
                bariátrica, lo hagan de manera remota, respondiendo
                un cuestionario. Este cuestionario puede ser editado
                por usted desde este minuto.
            </div>
            <div>
                <v-btn color="ternary" x-large depressed class="normal-btn mt-5" @click="goTo({name: 'doctor.questionnaire'})">
                    Ver Cuestionario
                </v-btn>
            </div>
            <div>
                <v-btn color="secondary" x-large depressed class="normal-btn mt-3" @click="goTo({name: 'doctor.signature'})">
                    Avanzar
                </v-btn>
            </div>
        </div>
        <waves force/>
    </div>
</template>

<script>
import Waves from "@/components/layouts/Waves";
export default {
    name: "RegisterDoctorSuccessCard",
    components: {Waves},
    methods: {
        onRedirect() {
            const vm = this
            vm.goTo({name: 'doctor.questionnaire'})
        }
    }
}
</script>

<style scoped>

.center-all {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.title-success {
    font-size: 30px;
    line-height: 32px;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 32px;
    margin-bottom: 12px;
}

.subtitle-success {
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
}

</style>