<template>
    <v-text-field
        v-bind="$attrs"
        v-on="$listeners"
        :disabled="disabled"
        :readonly="readonly"
        :outlined="outlined"
        :color="color"
        :label="label"
        :placeholder="placeholder"
        :value="defaultValue"
        :rules="rules"
        :solo="solo"
        :flat="flat"
        :prefix="prefix"
        :suffix="suffix"
        :dense="dense"
        :clearable="clearable"
        :inputmode="inputMode"
        :hide-details="!showDetails"
        :append-icon="appendIcon"
        :prepend-icon="prependIcon"
        :prepend-inner-icon="prependInnerIcon"
        :maxlength="maxLength"
        @input="onChange"
        @click:clear="onClear"
        @keypress.enter.prevent="onEnter"
        @keypress="isNumber($event)"
        ref="textField"
    />
</template>

<script>
export default {
    name: "KimsaTextField",
    props: {
        label: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number],
            default: '',
        },
        forceValue: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'secondary'
        },
        rules: {
            type: Array,
            default: () => []
        },
        inputMode: {
            type: String,
            default: 'text'
        },
        disableDots: {
            type: Boolean,
            default: false
        },
        maxLength: {
            type: Number,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        },
        solo: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        prefix: {
            type: String,
            default: ''
        },
        suffix: {
            type: String,
            default: ''
        },
        appendIcon: {
            type: String,
            default: '',
        },
        prependIcon: {
            type: String,
            default: ''
        },
        prependInnerIcon: {
            type: String,
            default: ''
        },
        showDetails: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            model: '',
        }
    },
    computed: {
        defaultValue() {
            const vm = this
            if (vm.inputMode === 'numeric' && !vm.disableDots)
                return vm.$options.filters.number(vm.model)
            return vm.model
        }
    },
    mounted() {
        const vm = this
        vm.setDefaults()
    },
    methods: {
        setDefaults() {
            const vm = this
            if (vm.value || vm.forceValue) {
                vm.model = vm.value
            }
        },
        focus() {
            const vm = this
            vm.$refs.textField.focus()
        },
        onChange(value) {
            const vm = this
            if (!value) return vm.model = ''
            if (vm.inputMode === 'numeric' && !vm.disableDots)
                return vm.model = Number(value.toString().replace(/\./g, ''))
            return vm.model = value
        },
        onEnter() {
            const vm = this
            vm.$refs.textField.blur()
        },
        onClear() {
            const vm = this
            vm.model = ''
        },
        isNumber(event) {
            const vm = this
            if (vm.inputMode !== 'numeric') return
            let charCode = event.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46)
                event.preventDefault();
            else return true
        },
    },
    watch: {
        value() {
            this.setDefaults()
        },
        model(val) {
            this.$emit('change', val)
        }
    }
}
</script>

<style scoped>

>>> .v-input__control {
    background: #FFFFFF;
    border-radius: 12px;
}

</style>
