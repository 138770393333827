<template>
    <v-text-field
        :color="color"
        v-model="model"
        :placeholder="placeholder"
        :rules="rules"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        @click:append="showPassword = !showPassword"
        :label="label"
        :disabled="disabled"
        :readonly="readonly"
        :outlined="outlined"
        :solo="solo"
        :flat="flat"
        :prefix="prefix"
        :suffix="suffix"
        :dense="dense"
        :hide-details="!showDetails"
        @keydown.enter="$emit('enter')"
        ref="field"
    ></v-text-field>
</template>

<script>
export default {
    name: "KimsaPasswordField",
    props: {
        label: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: 'secondary'
        },
        rules: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        },
        solo: {
            type: Boolean,
            default: false
        },
        showDetails: {
            type: Boolean,
            default: false,
        },
        prefix: {
            type: String,
            default: ''
        },
        suffix: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number],
            default: '',
        },
    },
    data() {
        return {
            model: '',
            showPassword: false,
        }
    },
    mounted() {
        const vm = this
        vm.setDefaults()
    },
    methods: {
        setDefaults() {
            const vm = this
            if (vm.value) vm.model = vm.value
        },
    },
    watch: {
        value() {
            this.setDefaults()
        },
        model(val) {
            this.$emit('change', val)
        }
    }
}
</script>

<style scoped>

>>> .v-input__control {
    background: #FFFFFF;
    border-radius: 12px;
}

</style>
